import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useCallback } from 'react';

import _ from 'lodash';
import { formatCurrency, getUnitName } from 'components/common/utils';
import styled from '@emotion/styled';
import { USER_TYPES } from 'components/common/constants';

const StyleViewTable = styled.div`
  .customTable {
    .p-datatable-tbody > tr.p-highlight {
      background: ${(props) => props.theme[`secondary-light-500`]};
      color: ${(props) => props.theme.primary};
    }
    .p-datatable-tbody > tr.p-selectable-row:focus {
      outline: none;
      outline-offset: -0.15rem;
    }

    .p-datatable-tbody
      > tr.p-selectable-row:not(.p-highlight):not(
        .p-datatable-emptymessage
      ):hover {
      background: ${(props) => props.theme[`secondary-light-500`]};
      color: ${(props) => props.theme.primary};
    }
  }
`;

const TeamMembersTable = ({
  shifts,
  setShiftObj,
  setShiftVisibleRight,
  shiftObj,
  customers,
  isUserAdmin,
  type,
}) => {
  // console.log('shifts ====== ', shifts);
  const onRowSelect = (event) => {
    setShiftObj({ ...event.data, type });
    setShiftVisibleRight(true);
  };

  const onRowUnselect = (event) => {
    setShiftObj();
    setShiftVisibleRight(true);
  };

  const dateBodyTemplate = (item) => {
    return (
      <span className=' text-truncate'>
        {`${item.active ? 'Active' : 'Inactive'}`}
      </span>
    );
  };

  const endDateBodyTemplate = (item) => {
    return (
      <span className=' text-truncate'>
        {`${moment(new Date(item.end)).format('DD/MM/YYYY, h:mm a')} `}
      </span>
    );
  };

  const titleBodyTemplate = (item) => {
    return (
      <span className='fw-semibold text-truncate'>{item.title || `...`}</span>
    );
  };

  const roleBodyTemplate = (item) => {
    return <span className='text-truncate'>{item.role}</span>;
  };

  const attendeesBodyTemplate = (item) => {
    const representative = item.attendees;

    return (
      <div>
        {representative && representative.length > 0 ? (
          <>
            {representative.map(({ firstname, lastname, uid }) => {
              return (
                <span
                  key={uid}
                  className='fw-semibold'
                >{`${firstname} ${lastname}`}</span>
              );
            })}
          </>
        ) : (
          <span style={{ color: 'orange' }}>Not assigned</span>
        )}
      </div>
    );
  };

  const attendeesApprovedBodyTemplate = (item) => {
    const representative = item.attendees;

    return (
      <div>
        {representative && representative.length > 0 ? (
          <>
            {representative.map((attendee) => {
              return (
                <span
                  key={attendee.uid}
                  className='fw-semibold'
                >
                  {!attendee?.approved &&
                    attendee?.timeEntry &&
                    attendee?.timeEntry !== 0 && <>SEND APPROVAL</>}

                  {attendee?.approved !== undefined &&
                    attendee?.approved &&
                    attendee?.timeEntry !== 0 && <>APPROVED</>}

                  {(!attendee?.timeEntry || attendee?.timeEntry === 0) && (
                    <>-</>
                  )}
                </span>
              );
            })}
          </>
        ) : (
          <span style={{ color: 'orange' }}>Not assigned</span>
        )}
      </div>
    );
  };

  const priorityBodyTemplate = useCallback(
    (item) => {
      const getUnitDetails = getUnitName({ unitId: item.customer, customers });

      return <>{getUnitDetails ? getUnitDetails.customerName : '-'}</>;
    },
    [customers]
  );

  const statusBodyTemplate = useCallback(
    (item) => {
      const getUnitDetails = getUnitName({ unitId: item.customer, customers });

      return (
        <span className='fw-semibold'>
          {getUnitDetails ? getUnitDetails.unitPrintName : '-'}
        </span>
      );
    },
    [customers]
  );

  const tagsBodyTemplate = (item) => {
    return (
      <>
        {item.tags && item.tags.length > 0 ? (
          <>
            {item.tags.map((obj) => {
              return <>ddsfsdf</>;
            })}
          </>
        ) : (
          <> {`...`}</>
        )}
      </>
    );
  };

  // const menuBodyTemplate = (item) => {
  // 	return <TableRowMenu />;
  // };

  const menuBodyTemplate = useCallback((item) => {
    return <>ddsfsdf</>;
  }, []);

  const hoursBodyTemplate = (rowData) => {
    // const attendees = rowData.attendees;
    // const getTotalHours = _.reduce(
    //   attendees || [],
    //   function (sum, n) {
    //     const { timeEntry } = n;
    //     return timeEntry ? sum + timeEntry : sum;
    //   },
    //   0
    // );
    return (
      <span>
        <strong>{formatCurrency(rowData.payRate || 0)}</strong>
      </span>
    );
  };
  return (
    <StyleViewTable>
      <DataTable
        className='customTable'
        size='normal'
        scrollable
        value={shifts}
        selectionMode='single'
        selection={shiftObj}
        dataKey='uid'
        {...(isUserAdmin
          ? { onRowSelect: onRowSelect, onRowUnselect: onRowUnselect }
          : {})}
        // onRowSelect={onRowSelect}
        // onRowUnselect={onRowUnselect}
        metaKeySelection={true}
        // scrollable
        scrollHeight='100vh'
      >
        <Column
          field='active'
          header='Status'
          sortable
          style={{ width: '5%', fontSize: '0.675rem' }}
          body={dateBodyTemplate}
        ></Column>
        <Column
          field='firstname'
          header='First Name'
          sortable
          style={{ width: '10%', fontSize: '0.675rem' }}
          // body={endDateBodyTemplate}
        ></Column>
        <Column
          field='lastname'
          header='Last Name'
          sortable
          style={{ width: '10%', fontSize: '0.675rem' }}
          // body={titleBodyTemplate}
        ></Column>

        <Column
          field='group'
          header='Group'
          sortable
          style={{ width: '10%', fontSize: '0.675rem' }}
          // body={descriptionBodyTemplate}
        ></Column>
        <Column
          field='role'
          header='Role'
          body={roleBodyTemplate}
          sortable
          style={{ width: '5%', fontSize: '0.675rem' }}
        ></Column>
        {/* {isUserAdmin && (
          <Column
            field='payRate'
            header='Pay Rate'
            sortable
            body={hoursBodyTemplate}
            style={{ width: '5%', fontSize: '0.675rem' }}
          ></Column>
        )} */}
        <Column
          field='email'
          header='Email'
          sortable
          // body={attendeesApprovedBodyTemplate}
          style={{ width: '15%', fontSize: '0.675rem' }}
        ></Column>

        {/* <Column
          field='customer'
          header='Customer'
          sortable
          body={priorityBodyTemplate}
          style={{ width: '15%', fontSize: '0.675rem' }}
        ></Column>
        <Column
          field='customer'
          header='Unit'
          sortable
          body={statusBodyTemplate}
          style={{ width: '30%', fontSize: '0.675rem' }}
        ></Column> */}

        {/* {isUserAdmin && (
          <Column
            field=''
            header=''
            sortable={false}
            body={menuBodyTemplate}
            style={{ width: '10%', fontSize: '0.675rem' }}
          ></Column>
        )} */}
      </DataTable>
    </StyleViewTable>
  );
};

export default TeamMembersTable;
