import styled from '@emotion/styled';
import _ from 'lodash';
import { useCallback, useRef } from 'react';
import { Form } from 'react-final-form';
import { generate as generateUUID } from 'short-uuid';
import { TOAST_TYPE } from '../../common/constants';
import { useToast } from '../../common/hooks/useToast';
import { useApiService } from '../../context';
import AddShiftForm from '../AddShiftForm';
import {
  StyledFooterDiv,
  StyledHeaderDiv,
} from '../../customers/AddCustomerFormContainer';
import { ButtonBase } from '../../common';
import { useShiftService } from '../ShiftContext';
import moment from 'moment';
import { useClickAway } from 'react-use';
import { useSideBarClickAway } from '../../common/hooks/useSideBarClickAway';
import axios from 'axios';
import { getShiftNotificationBody } from 'components/common/utils';

export const StyledDropDownDiv = styled.div`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
  .customDropdown {
    width: 100%;
  }
  .customDropdown:not(.p-disabled):hover {
    border-color: #6366f1;
  }

  .customDropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
    padding: 8px 8px;
    font-size: 12px;
  }

  .customDropdown .p-dropdown-trigger {
    width: 2rem;
  }

  .customDropdown .p-dropdown-trigger-icon {
    font-size: 10px;
  }

  .p-disabled,
  .p-component:disabled {
    opacity: 0.6;
    background-color: #e9ecef;
  }
`;

export const StyledInput = styled.input`
  padding: 8px 8px;
  font-size: 12px;
  width: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
  margin: 0;

  &:hover {
    border-color: #6366f1;
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: #6366f1;
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 0.6;
    &:hover {
      border-color: #ced4da;
    }
  }
`;

const StyledLabel = styled.label`
  font-size: 12px;
  display: inline-block;
  font-weight: 500;
`;
const StyledH5 = styled.h5`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
`;
const StyledDiv = styled.div`
  right: 12px !important;
  top: 42px !important;

  i {
    cursor: pointer;
    z-index: 200;
    font-size: large;
    color: #6c757d;

    &:hover {
      color: #7f00ff;
    }
  }
`;

const AddShiftFormContainer = ({
  visibleLeft,
  setVisibleLeft,
  auth,
  newGroup,
  setNewGroup,
}) => {
  const {
    createDoc,
    database,
    rotaPadUser,
    setRotaPadGroups,
    fetchData,
    setRotaPadWorkSpaces,
  } = useApiService();
  const { triggerToast } = useToast();
  const { rotaCurrentDate, createShifDoc } = useShiftService();
  const ref = useRef(null);
  useSideBarClickAway({
    ref,
    setVisibleLeft: () => setVisibleLeft(false),
    setNewGroup: () => setNewGroup({}),
  });
  // useClickAway(ref, () => {
  //   setVisibleLeft(false);
  //   setNewGroup({});
  // });
  const validate = (data) => {
    let errors = {};

    if (!data.start) {
      errors.start = 'Start date/time is required.';
    }

    if (!data.title) {
      errors.title = 'Title is required.';
    }

    if (!data.type) {
      errors.type = 'Shift type is required.';
    }

    if (!data.customer) {
      errors.customer = 'Customer is required.';
    }
    if (!data.status) {
      errors.status = 'Status is required.';
    }

    return errors;
  };

  const handleSubmitForm = useCallback(
    async (data, form) => {
      const { attendees: previousAttendees } = newGroup;
      const { attendees: nextAttendees } = data;

      const uuid = data.uid ? data.uid : generateUUID();

      const newGroupObj = _.pickBy(
        {
          ...data,
          uid: uuid,
        },
        _.identity
      );
      if (rotaPadUser && rotaCurrentDate) {
        const success = await createShifDoc(
          database,
          `shifts/${moment(rotaCurrentDate).format('YYYY')}/${moment(
            rotaCurrentDate
          ).format('MMMM')}/${uuid}`,
          newGroupObj
        );

        if (_.isEmpty(previousAttendees) && !_.isEmpty(nextAttendees)) {
          //send email notication: Team Member ASSIGNED to a shift
          // case 1 previousAttendees is empty and nextAttendees is not empty
          await axios.post(
            'https://api.sendinblue.com/v3/smtp/email',
            getShiftNotificationBody({
              email: nextAttendees[0].email,
              firstname: nextAttendees[0].firstname,
              lastname: nextAttendees[0].lastname,
              shiftName: `${data.title}, with start date of ${moment(
                data.start
              ).format('ddd Do, MMM YYYY')}`,
              subject: 'SuperChurch Shift Assignment',
              verb: 'assigned',
            }),
            {
              headers: {
                'api-key': `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`,
              },
            }
          );
        } else if (
          !_.isEmpty(previousAttendees) &&
          !_.isEmpty(nextAttendees) &&
          previousAttendees[0].email !== nextAttendees[0].email
        ) {
          //send email notication: Team Member ASSIGNED and UNASSIGNED to a shift
          // case 2 previousAttendees is not empty and nextAttendees is not empty and emails ARE NOT the same
          await axios.post(
            'https://api.sendinblue.com/v3/smtp/email',
            getShiftNotificationBody({
              email: nextAttendees[0].email,
              firstname: nextAttendees[0].firstname,
              lastname: nextAttendees[0].lastname,
              shiftName: `${data.title}, with start date of ${moment(
                data.start
              ).format('ddd Do, MMM YYYY')}`,
              subject: 'SuperChurch Shift Assigned',
              verb: 'assigned',
            }),
            {
              headers: {
                'api-key': `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`,
              },
            }
          );

          await axios.post(
            'https://api.sendinblue.com/v3/smtp/email',
            getShiftNotificationBody({
              email: previousAttendees[0].email,
              firstname: previousAttendees[0].firstname,
              lastname: previousAttendees[0].lastname,
              shiftName: `${data.title}, with start date of ${moment(
                data.start
              ).format('ddd Do, MMM YYYY')}`,
              subject: 'SuperChurch Shift Unassigned',
              verb: 'unassigned',
            }),
            {
              headers: {
                'api-key': `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`,
              },
            }
          );
        } else if (!_.isEmpty(previousAttendees) && _.isEmpty(nextAttendees)) {
          //send email notication: Team Member UNASSIGNED to a shift
          // case 3 previousAttendees is not empty and nextAttendees is empty
          await axios.post(
            'https://api.sendinblue.com/v3/smtp/email',
            getShiftNotificationBody({
              email: previousAttendees[0].email,
              firstname: previousAttendees[0].firstname,
              lastname: previousAttendees[0].lastname,
              shiftName: `${data.title}, with start date of ${moment(
                data.start
              ).format('ddd Do, MMM YYYY')}`,
              subject: 'SuperChurch Shift Unassigned',
              verb: 'unassigned',
            }),
            {
              headers: {
                'api-key': `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`,
              },
            }
          );
        } else if (
          !_.isEmpty(previousAttendees) &&
          !_.isEmpty(nextAttendees) &&
          previousAttendees[0].email === nextAttendees[0].email
        ) {
          //DO NOT send email notication
          // case 4 previousAttendees is not empty and nextAttendees is not empty and emails ARE the same
        }

        if (success) {
          triggerToast({
            message: 'Shift successfully updated!',
            type: TOAST_TYPE.success,
          });
        } else {
          triggerToast({
            message: 'Shift could not be updated!',
            type: TOAST_TYPE.warning,
          });
        }

        form.reset();
        form.resetFieldState('start');
        form.resetFieldState('end');
        form.resetFieldState('title');

        form.resetFieldState('description');
        form.resetFieldState('status');

        form.resetFieldState('attendees');
        form.resetFieldState('type');
        form.resetFieldState('customer');
        setNewGroup({});
        setVisibleLeft(false);
      }
    },
    [
      createDoc,
      database,
      fetchData,
      rotaPadUser,
      setNewGroup,
      setVisibleLeft,
      triggerToast,
      setRotaPadWorkSpaces,
      rotaCurrentDate,
      newGroup,
    ]
  );

  // console.log('newGroup ===== ', newGroup);
  return (
    <Form
      onSubmit={handleSubmitForm}
      initialValues={newGroup}
      validate={validate}
      render={({
        handleSubmit,
        pristine,
        submitting,
        hasValidationErrors,
        form,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div
              // ref={ref}
              className={`offcanvas offcanvas-end  ${
                visibleLeft ? 'show shadow-lg' : 'hiding'
              }`}
              // tabIndex='-1'
              id='offcanvasExample'
              aria-labelledby='offcanvasExampleLabel'
              style={{ width: '24em', zIndex: 555 }}
            >
              <StyledHeaderDiv className='offcanvas-header'>
                <h5
                  className='offcanvas-title'
                  style={{ lineHeight: '27px' }}
                >
                  {`${newGroup.uid ? 'Update' : 'Add'} shift`}
                </h5>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='offcanvas'
                  aria-label='Close'
                  onClick={() => {
                    setVisibleLeft(false);
                    form.reset();
                    form.resetFieldState('start');
                    form.resetFieldState('end');
                    form.resetFieldState('title');

                    form.resetFieldState('description');
                    form.resetFieldState('status');

                    form.resetFieldState('attendees');
                    form.resetFieldState('type');
                    form.resetFieldState('customer');

                    // form.resetFieldState('address');

                    setNewGroup({});
                  }}
                ></button>
              </StyledHeaderDiv>
              <div>
                <div className='offcanvas-body p-0'>
                  <div
                    className='d-flex flex-column mb-0'
                    style={{ height: 'calc(100vh - 118px)', overflow: 'auto' }}
                  >
                    {/* <div className='col-12 mb-1 text-muted px-3 pt-3'>
                      The common and simplest way to handle client requests is
                      when work orders and its data are pulled into RotaPad.
                      Once this is available, RotaPad automatically creates a
                      work order with this information and you can then allocate
                      resources to fulfill them.
                    </div> */}
                    <AddShiftForm
                      hasValidationErrors={hasValidationErrors}
                      submitting={submitting}
                      pristine={pristine}
                      handleSubmit={handleSubmit}
                      visibleLeft={visibleLeft}
                      uid={newGroup.uid}
                    />
                  </div>
                </div>
              </div>

              <StyledFooterDiv className='offcanvas-header'>
                <div className='btn-toolbar my-2 my-sm-0 my-md-0'>
                  <div className='d-flex flex-row mb-0'>
                    <div className='me-2'>
                      <ButtonBase
                        type='button'
                        variant='primary'
                        size='small'
                        onClick={() => {
                          setVisibleLeft(false);
                          form.reset();
                          form.resetFieldState('start');
                          form.resetFieldState('end');
                          form.resetFieldState('title');

                          form.resetFieldState('description');
                          form.resetFieldState('status');

                          form.resetFieldState('attendees');
                          form.resetFieldState('customer');
                          form.resetFieldState('type');

                          // form.resetFieldState('address');

                          setNewGroup({});
                        }}
                      >
                        Cancel
                      </ButtonBase>
                    </div>
                    <div>
                      <ButtonBase
                        type='submit'
                        variant='primary'
                        size='small'
                        disabled={pristine || submitting || hasValidationErrors}
                      >
                        {`${newGroup.uid ? 'Update' : 'Submit'}`}
                      </ButtonBase>
                    </div>
                  </div>
                </div>
              </StyledFooterDiv>
            </div>
          </form>
        );
      }}
    />
  );
};

export default AddShiftFormContainer;
