import styled from '@emotion/styled';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { useCallback, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import TeamMemberAddress from '../TeamMemberAddress';
import { Checkbox } from 'primereact/checkbox';
import { StyledInputPayRate } from '../../../customers/AddCustomerForm';
import { InputNumber } from 'primereact/inputnumber';
import { useApiService } from '../../../context';
import _ from 'lodash';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useToast } from 'components/common/hooks/useToast';
import { TOAST_TYPE } from 'components/common/constants';
import { ProgressSpinner } from 'primereact/progressspinner';
import axios from 'axios';

export const StyledDropDownDiv = styled.div`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
  .customDropdown {
    width: 100%;
  }
  .customDropdown:not(.p-disabled):hover {
    border-color: #6366f1;
  }

  .customDropdown .p-dropdown-label {
    background: transparent;
    border: 0 none;
    padding: 8px 8px;
    font-size: 12px;
  }

  .customDropdown .p-dropdown-trigger {
    width: 2rem;
  }

  .customDropdown .p-dropdown-trigger-icon {
    font-size: 10px;
  }

  .p-disabled,
  .p-component:disabled {
    opacity: 0.6;
    background-color: #e9ecef;
  }
`;

export const StyledInput = styled.input`
  padding: 8px 8px;
  font-size: 12px;
  width: 100% !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #495057;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  appearance: none;
  border-radius: 6px;
  margin: 0;

  &:hover {
    border-color: #6366f1;
  }
  &:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c7d2fe;
    border-color: #6366f1;
  }
  &:disabled {
    background-color: #e9ecef;
    opacity: 0.6;
    &:hover {
      border-color: #ced4da;
    }
  }
`;

const StyledLabel = styled.label`
  font-size: 12px;
  display: inline-block;
  font-weight: 500;
`;
const StyledH5 = styled.h5`
  // border-width: 0 0 0 2px;
  // border-style: solid;
  // padding-left: 12px;
  // border-color: #6554c0;
  color: #6554c0;
  font-size: 18px;
  padding-top: 16px;
`;
const StyledDiv = styled.div`
  right: 12px !important;
  top: 40px !important;
  cursor: pointer;
  color: #6c757d;

  &:hover {
    color: #7f00ff;
  }

  i {
    cursor: pointer;
    z-index: 200;
    font-size: large;
    color: #6c757d;

    &:hover {
      color: #7f00ff;
    }
  }
`;

const TeamMemberForm = ({
  handleSubmit,
  uid,
  pristine,
  submitting,
  hasValidationErrors,
  visibleLeft,
  rotaPadUser,
  disabled,
  isSelf = false,
  email,
  formType = 'invite',
  teamMember,
}) => {
  const [showNewPassword, setShowNewPassword] = useState({
    hide: true,
    info: 'Show',
  });
  const { triggerToast } = useToast();
  const auth = getAuth();
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return (
      isFormFieldValid(meta) && <small className='p-error'>{meta.error}</small>
    );
  };
  const {
    rotaPadUserGroupsTopic,
    isRotaPadUserAdmin,
    isRotaPadUserSupport,
    rotaPadInvitesTopic,
  } = useApiService();
  const [isEmailSending, setIsEmailSending] = useState(false);

  // const isSelf = false;
  const isUserAdmin = isRotaPadUserAdmin || isRotaPadUserSupport;
  const convertUserGroupstoArray = useMemo(() => {
    return _.isObject(rotaPadUserGroupsTopic) &&
      Object.keys(rotaPadUserGroupsTopic).length > 0
      ? Object.keys(rotaPadUserGroupsTopic).map((key) => {
          return {
            value: rotaPadUserGroupsTopic[key].name,
            ...rotaPadUserGroupsTopic[key],
          };
        })
      : [];
  }, [rotaPadUserGroupsTopic]);

  const handleResetPassword = useCallback(async () => {
    try {
      setIsEmailSending(true);
      await sendPasswordResetEmail(auth, email);
      triggerToast({
        message: 'Reset password email successfully sent to ' + email,
        type: TOAST_TYPE.success,
      });
      setIsEmailSending(false);
    } catch (error) {
      setIsEmailSending(false);
      triggerToast({
        message: 'Reset password email could not be sent!',
        type: TOAST_TYPE.warning,
      });
    }
  }, [auth, email]);

  const handleResendInviteEmail = useCallback(async () => {
    try {
      setIsEmailSending(true);
      const data = teamMember;
      const invites = _.isObject(rotaPadInvitesTopic)
        ? Object.keys(rotaPadInvitesTopic).map(
            (key) => rotaPadInvitesTopic[key]
          )
        : [];
      const uuid = _.find(invites, { email: data.email })?.uid;
      if (uuid) {
        await axios.post(
          'https://api.sendinblue.com/v3/smtp/email',
          {
            sender: { name: 'RotaPad', email: 'noreply@rotapad.com' },
            to: [
              {
                email: data.email,
                name: data.firstname + ' ' + data.lastname,
              },
            ],
            subject: 'OBC SuperChurch Workspace Invitation',
            htmlContent: `<!doctype html>
              <html>
                <head>
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
                  <title>RotaPad Invite Email</title>
                  <style>
              @media only screen and (max-width: 620px) {
                table.body h1 {
                  font-size: 28px !important;
                  margin-bottom: 10px !important;
                }
              
                table.body p,
              table.body ul,
              table.body ol,
              table.body td,
              table.body span,
              table.body a {
                  font-size: 16px !important;
                }
              
                table.body .wrapper,
              table.body .article {
                  padding: 10px !important;
                }
              
                table.body .content {
                  padding: 0 !important;
                }
              
                table.body .container {
                  padding: 0 !important;
                  width: 100% !important;
                }
              
                table.body .main {
                  border-left-width: 0 !important;
                  border-radius: 0 !important;
                  border-right-width: 0 !important;
                }
              
                table.body .btn table {
                  width: 100% !important;
                }
              
                table.body .btn a {
                  width: 100% !important;
                }
              
                table.body .img-responsive {
                  height: auto !important;
                  max-width: 100% !important;
                  width: auto !important;
                }
              }
              @media all {
                .ExternalClass {
                  width: 100%;
                }
              
                .ExternalClass,
              .ExternalClass p,
              .ExternalClass span,
              .ExternalClass font,
              .ExternalClass td,
              .ExternalClass div {
                  line-height: 100%;
                }
              
                .apple-link a {
                  color: inherit !important;
                  font-family: inherit !important;
                  font-size: inherit !important;
                  font-weight: inherit !important;
                  line-height: inherit !important;
                  text-decoration: none !important;
                }
              
                #MessageViewBody a {
                  color: inherit;
                  text-decoration: none;
                  font-size: inherit;
                  font-family: inherit;
                  font-weight: inherit;
                  line-height: inherit;
                }
              
                .btn-primary table td:hover {
                  background-color: #34495e !important;
                }
              
                .btn-primary a:hover {
                  background-color: #34495e !important;
                  border-color: #34495e !important;
                }
              }
              </style>
                </head>
                <body style="background-color: #f6f6f6; font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 14px; line-height: 1.4; margin: 0; padding: 0; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
                  <span class="preheader" style="color: transparent; display: none; height: 0; max-height: 0; max-width: 0; opacity: 0; overflow: hidden; mso-hide: all; visibility: hidden; width: 0;">This is preheader text. Some clients will show this text as a preview.</span>
                  <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f6f6f6; width: 100%;" width="100%" bgcolor="#f6f6f6">
                    <tr>
                      <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">&nbsp;</td>
                      <td class="container" style="font-family: sans-serif; font-size: 14px; vertical-align: top; display: block; max-width: 580px; padding: 10px; width: 580px; margin: 0 auto;" width="580" valign="top">
                        <div class="content" style="box-sizing: border-box; display: block; margin: 0 auto; max-width: 580px; padding: 10px;">
              
                          <!-- START CENTERED WHITE CONTAINER -->
                          <table role="presentation" class="main" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; background: #ffffff; border-radius: 3px; width: 100%;" width="100%">
              
                            <!-- START MAIN CONTENT AREA -->
                            <tr>
                              <td class="wrapper" style="font-family: sans-serif; font-size: 14px; vertical-align: top; box-sizing: border-box; padding: 20px;" valign="top">
                                <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;" width="100%">
                                  <tr>
                                    <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">
                                    <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;"><img src="https://www.oxfordbiblechurch.co.uk/templates/corporate_response/images/s5_logo.png"  alt="user-img" style="width: 185px; cursor: pointer;"></p>
                                      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">Hi ${
                                        data.firstname + ' ' + data.lastname
                                      },</p>
                                      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">You have been invited to join the SuperChurch RotaPad Workspace! <br /><strong>${
                                        data.email
                                      }</strong> has been set as your primary email. Click on the button below to create a new password for your RotaPad account.</p>
                                      <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; box-sizing: border-box; width: 100%;" width="100%">
                                        <tbody>
                                          <tr>
                                            <td align="left" style="font-family: sans-serif; font-size: 14px; vertical-align: top; padding-bottom: 15px;" valign="top">
                                              <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: auto;">
                                                <tbody>
                                                  <tr>
                                                    <td style="font-family: sans-serif; font-size: 14px; vertical-align: top; border-radius: 5px; text-align: center; background-color: #3498db;" valign="top" align="center" bgcolor="#3498db"> <a href="https://obc.rotapad.com/onboard?_atemail=${
                                                      data.email
                                                    }&_fname=${
              data.firstname
            }&_lname=${
              data.lastname
            }&_uuid=${uuid}" target="_blank" style="border: solid 1px #3498db; border-radius: 5px; box-sizing: border-box; cursor: pointer; display: inline-block; font-size: 14px; font-weight: bold; margin: 0; padding: 12px 25px; text-decoration: none; text-transform: capitalize; background-color: #3498db; border-color: #3498db; color: #ffffff;">Join SuperChurch Workspace</a> </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">Once onboarded, you become part of the workspace. You can also invite other team members to sign up using their email address.</p>
                                      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; margin-bottom: 15px;">Have a nice day ahead!</p>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
              
                          <!-- END MAIN CONTENT AREA -->
                          </table>
                          <!-- END CENTERED WHITE CONTAINER -->
              
                          <!-- START FOOTER -->
                          <div class="footer" style="clear: both; margin-top: 10px; text-align: center; width: 100%;">
                            <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;" width="100%">
                              <tr>
                                <td class="content-block" style="font-family: sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; color: #999999; font-size: 12px; text-align: center;" valign="top" align="center">
                                  <span class="apple-link" style="color: #999999; font-size: 12px; text-align: center;">Rota Pad Services Inc. T2Z 1J9, Calgary AB.</span>
                                  <br> Do you want fnd out more? <a href="https://rotapad.com" style="text-decoration: underline; color: #999999; font-size: 12px; text-align: center;">RotaPad.com</a>.
                                </td>
                              </tr>
                              <tr>
                                <td class="content-block powered-by" style="font-family: sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; color: #999999; font-size: 12px; text-align: center;" valign="top" align="center">
                                  Powered by <a href="https://rotapad.com" style="color: #999999; font-size: 12px; text-align: center; text-decoration: none;">RotaPad APIs</a>.
                                </td>
                              </tr>
                            </table>
                          </div>
                          <!-- END FOOTER -->
              
                        </div>
                      </td>
                      <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">&nbsp;</td>
                    </tr>
                  </table>
                </body>
              </html>`,
            headers: {
              charset: 'iso-8859-1',
            },
          },
          {
            headers: {
              'api-key': `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`,
            },
          }
        );
        triggerToast({
          message: 'Invite successfully re-sent to ' + email,
          type: TOAST_TYPE.success,
        });
      } else {
        triggerToast({
          message: 'No pending invites for ' + email,
          type: TOAST_TYPE.warning,
        });
      }

      setIsEmailSending(false);
    } catch (error) {
      setIsEmailSending(false);
      triggerToast({
        message: 'Invite email could not be sent!',
        type: TOAST_TYPE.warning,
      });
    }
  }, [auth, email, teamMember, rotaPadInvitesTopic]);
  return (
    <div className='w-100 p-3'>
      <div className='row g-3'>
        <div className='col-12 mb-1'>
          <Field
            name='firstname'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='firstname'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    First Name <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledInput
                    id='firstname'
                    {...input}
                    className={classNames({
                      'p-invalid': isFormFieldValid(meta),
                    })}
                    disabled={!isUserAdmin || isSelf || disabled}
                  />
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div>

        <div className='col-12 mt-1 mb-1'>
          <Field
            name='lastname'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='lastname'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    Last Name <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledInput
                    id='lastname'
                    {...input}
                    className={classNames({
                      'p-invalid': isFormFieldValid(meta),
                    })}
                    disabled={!isUserAdmin || isSelf || disabled}
                  />
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div>
        {isUserAdmin && (
          <div className='col-12 mt-1 mb-1'>
            <Field
              name='email'
              render={({ input, meta }) => (
                <div className='field'>
                  <div className='block'>
                    <StyledLabel
                      htmlFor='email'
                      className={classNames({
                        'p-error': isFormFieldValid(meta),
                      })}
                    >
                      Email <span className='text-danger'>*</span>
                    </StyledLabel>
                    <StyledInput
                      id='email'
                      {...input}
                      className={classNames({
                        'p-invalid': isFormFieldValid(meta),
                      })}
                      disabled={
                        uid !== undefined || !isUserAdmin || isSelf || disabled
                      }
                      // disabled={!isUserAdmin}
                    />
                  </div>
                  {getFormErrorMessage(meta)}
                </div>
              )}
            />
          </div>
        )}

        {isUserAdmin && (
          <div className='col-12 mt-1 mb-1'>
            <Field
              name='payRate'
              render={({ input, meta }) => {
                // console.log('input ===== ', input);
                return (
                  <div className='field'>
                    <div className='block'>
                      <StyledLabel htmlFor='phone'>Pay rate</StyledLabel>
                      <StyledInputPayRate>
                        <InputNumber
                          inputId='stacked'
                          // {...input}
                          value={input.value || 0}
                          onValueChange={(e) => {
                            // console.log('asdasdas ====', e.value);
                            input.onChange(e.value);
                          }}
                          showButtons
                          mode='currency'
                          currency='GBP'
                          className='w-100'
                          disabled={!isUserAdmin || disabled || isSelf}
                        />
                      </StyledInputPayRate>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        )}
      </div>

      {/* <Field
				name='address'
				label='address'
				component={TeamMemberAddress}
				labelPosition='right'
				visibleLeft={visibleLeft}
				disabled={isSelf}
			/> */}

      <StyledH5 className='mt-3 mb-2'>Assignment</StyledH5>
      <div className='row g-3'>
        <div className='col-12 mb-1'>
          <Field
            name='role'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='role'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    Role <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledDropDownDiv>
                    <Dropdown
                      id='role'
                      {...input}
                      options={[
                        { name: 'Admin', value: 'Admin' },
                        { name: 'Support', value: 'Support' },
                        { name: 'User', value: 'User' },
                      ]}
                      optionLabel='name'
                      className='customDropdown'
                      disabled={!isUserAdmin || disabled || isSelf}
                    />
                  </StyledDropDownDiv>
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div>

        <div className='col-12 mt-1 mb-1'>
          <Field
            name='group'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <StyledLabel
                    htmlFor='group'
                    className={classNames({
                      'p-error': isFormFieldValid(meta),
                    })}
                  >
                    Group <span className='text-danger'>*</span>
                  </StyledLabel>
                  <StyledDropDownDiv>
                    <Dropdown
                      id='group'
                      {...input}
                      options={convertUserGroupstoArray}
                      optionLabel='name'
                      className='w-100 p-inputtext-sm customDropdown'
                      disabled={!isUserAdmin || disabled}
                    />
                  </StyledDropDownDiv>
                </div>
                {getFormErrorMessage(meta)}
              </div>
            )}
          />
        </div>
      </div>
      <StyledH5 className='mt-3 mb-2'>Privacy and security</StyledH5>
      <div className='row g-3'>
        <div className='col-12 mb-1'>
          <Field
            name='active'
            type='checkbox'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <Checkbox
                    inputId='active'
                    {...input}
                    disabled={!isUserAdmin || disabled || isSelf}
                  />
                  <StyledLabel
                    htmlFor='active'
                    className='ms-2'
                  >
                    Active
                  </StyledLabel>
                </div>
              </div>
            )}
          />
        </div>

        <div className='col-12 mt-1 mb-1'>
          <Field
            name='endUserConsent'
            type='checkbox'
            render={({ input, meta }) => (
              <div className='field'>
                <div className='block'>
                  <Checkbox
                    inputId='endUserConsent'
                    {...input}
                    disabled={!isUserAdmin || disabled || isSelf}
                  />
                  <StyledLabel
                    htmlFor='endUserConsent'
                    className='ms-2'
                  >
                    End User Consent
                  </StyledLabel>
                </div>
              </div>
            )}
          />
        </div>
      </div>

      {isUserAdmin && formType !== 'invite' && (
        <StyledH5 className='mt-3 mb-2'>
          Reset Password <br />
          <button
            onClick={handleResetPassword}
            type='button'
            className='btn btn-sm btn-outline-primary btnPrimaryComments me-2 mt-2'
          >
            Send Reset Password Email
            {isEmailSending && (
              <ProgressSpinner
                style={{ marginLeft: '4px', width: '10px', height: '10px' }}
              />
            )}
          </button>
        </StyledH5>
      )}

      {isUserAdmin && formType === 'invite' && (
        <StyledH5 className='mt-3 mb-2'>
          Resend Invite <br />
          <button
            onClick={handleResendInviteEmail}
            type='button'
            className='btn btn-sm btn-outline-primary btnPrimaryComments me-2 mt-2'
          >
            Resend Invite Email
            {isEmailSending && (
              <ProgressSpinner
                style={{ marginLeft: '4px', width: '10px', height: '10px' }}
              />
            )}
          </button>
        </StyledH5>
      )}
    </div>
  );
};

export default TeamMemberForm;
