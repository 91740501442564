import styled from '@emotion/styled';
import { ButtonBase, Pill } from '../../../../common';
import { BADGE_STYLE } from '../../../../common/constants';
import { BsCaretRightFill } from 'react-icons/bs';
import { useCallback, useContext, useMemo } from 'react';
import { ReportContext } from '../../../../reports/ReportContext';
import _ from 'lodash';
import { formatCurrency, formatHour } from '../../../../common/utils';
import CustomerInvoiceTable from '../InvoiceTable';
import { useToggle } from 'react-use';
import TeamMemberWorkSheetTable from '../InvoiceTable';
import { useApiService } from 'components/context';
import { BiPlus } from 'react-icons/bi';
import {
  MY_WORKSHEET_REPORT_TYPE,
  WORKSHEET_REPORT_TYPE,
} from 'components/reports';

const EntryStyledDiv = styled.div`
  padding: 8px;
  border-bottom: 1px solid #e3e3e3;
  font-size: 12px;
  font-weight: 600;
`;
const EntryNameStyledDIv = styled.div`
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme[`primary`]};
  }
`;
const Entry = ({ uid, name, computedShifts, unitPrintName, customers }) => {
  const [on, toggle] = useToggle(false);
  const {
    item,
    date,
    expandAllUnits,
    setExpandAllUnits,
    handleOpenSidebar,
    setPdfInvoicePayload,
  } = useContext(ReportContext);
  // const { rotaPadTeamMembersTopic } = useApiService();
  // console.log('item ===== ', item);
  // console.log('uid ===== ', uid);
  const { count, totalDue, totalHours } = useMemo(() => {
    if (!computedShifts) return { count: 0, totalHours: 0, totalDue: 0 };
    // Get all attendees from shiftsCollection
    const attendeesCollection = _.reduce(
      computedShifts,
      function (sum, n) {
        const { attendees } = n;
        return attendees && attendees.length > 0
          ? [...sum, ...attendees]
          : [...sum];
      },
      []
    );

    // console.log('attendeesCollection === ', attendeesCollection);

    // Get all attendees hours
    const attendeesTotalHours = _.reduce(
      attendeesCollection,
      function (sum, n) {
        const { timeEntry } = n;
        return timeEntry ? sum + timeEntry : sum;
      },
      0
    );

    // console.log('attendeesTotalHours === ', attendeesTotalHours);

    return {
      count: computedShifts.length,
      totalHours: attendeesTotalHours,
      totalDue: 0,
    };
  }, [computedShifts]);

  const renderTable = useMemo(() => {
    return (
      <TeamMemberWorkSheetTable
        tableData={computedShifts}
        payRate={_.find(item, { uid })?.payRate || 0}
        reportType='Worksheet'
        unitName={unitPrintName}
        customers={customers}
        uid={uid}
      />
    );
  }, [computedShifts, unitPrintName, item, uid, customers]);

  const handleClickPreview = useCallback(() => {
    // const getUnitDetails = getUnitName({ unitId, customers });
    // console.log('getUnitDetails ===== ', getUnitDetails);
    setPdfInvoicePayload({
      computedShifts,
      reportType: MY_WORKSHEET_REPORT_TYPE,
      teamMember: _.find(item, { uid }),
      date,
      customers,
      uid,
    });
    handleOpenSidebar(true);
  }, [computedShifts, item, uid, date, customers]);

  return (
    <div>
      <EntryStyledDiv
        // key={unitId}
        className='d-flex justify-content-between align-items-center'
      >
        <EntryNameStyledDIv className='d-flex justify-content-between align-items-center'>
          <div
            onClick={() => {
              setExpandAllUnits(false);
              toggle();
            }}
          >
            <BsCaretRightFill
              style={{ marginRight: '6px', marginTop: '-3px' }}
            />
            {name}
          </div>

          <Pill
            content={`${computedShifts.length} ${
              computedShifts.length === 1 ? 'shift' : 'shifts'
            }`}
            bgStyle={{
              ...(computedShifts.length > 0
                ? BADGE_STYLE.Admin
                : BADGE_STYLE.Customer),
              marginBottom: 0,
              marginLeft: '8px',
            }}
            toolTipMessage={`${computedShifts.length} ${
              computedShifts.length === 1 ? 'shift' : 'shifts'
            }`}
          />
          {computedShifts.length > 0 && (
            <Pill
              content={`View worksheet`}
              bgStyle={{
                ...BADGE_STYLE.Admin,
                marginBottom: 0,
                marginLeft: '0px',
                cursor: 'pointer',
              }}
              toolTipMessage={`View worksheet pdf`}
              onClick={handleClickPreview}
            />
          )}
        </EntryNameStyledDIv>
        <div>{formatHour(totalHours)}</div>
      </EntryStyledDiv>
      {(expandAllUnits || on) && <div>{renderTable}</div>}
    </div>
  );
};

export default Entry;
