import styled from '@emotion/styled';
import { ReportContext } from '../../ReportContext';
import { useContext, useMemo, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { useApiService } from '../../../context';
import _ from 'lodash';
import { useToast } from '../../../common/hooks/useToast';
import { TOAST_TYPE } from '../../../common/constants';
import {
  convertObjectToArray,
  formatCurrency,
  formatHour,
} from 'components/common/utils';
import Details from '../Details';
import Header, { CustomerIdStyledDIv, CustomerNameDivStyled } from '../Header';
import HeaderToggle from 'components/reports/common/HeaderToggle';
import { BsCaretDownFill, BsCaretRightFill } from 'react-icons/bs';

const TotalDueSpanStyled = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #8f8f8f;
`;

const TotalValueStyledSpan = styled.span`
  font-size: 14px;
  font-weight: 600;
`;
const AtAGlanceStyledDIv = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #8f8f8f;
`;
const DateRangeStyledDIv = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => props.theme.primary};
  width: 152px;

  .p-calendar {
    width: 100%;
  }
  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem ${(props) => props.theme[`secondary-light-500`]};
    border-radius: 3px;
    background-color: ${(props) => props.theme[`secondary-light-500`]};
  }
  .p-inputtext:enabled:hover {
    border-color: #6366f1;
    border-radius: 3px;
    background-color: ${(props) => props.theme[`secondary-light-500`]};
    box-shadow: 0 0 0 0.2rem ${(props) => props.theme[`secondary-light-500`]};
  }

  input {
    cursor: pointer;
    padding: 0;
    font-size: 12px;
    text-align: right;
    width: 100% !important;
    font-weight: 600;
    color: ${(props) => props.theme.primary};
    background: #ffffff;
    border: none;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
      box-shadow 0.2s;
    appearance: none;
    border-radius: 0px;
    margin: 0;

    &:hover {
      border-color: #6366f1;
    }
    &:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #c7d2fe;
      border-color: #6366f1;
    }
    &:disabled {
      background-color: #e9ecef;
      opacity: 0.6;
      &:hover {
        border-color: #ced4da;
      }
    }
  }
`;
const BorderedBottomDiv = styled.div`
  border-bottom: 1px solid #e3e3e3;
  padding: 0 8px 4px 8px;
  margin-bottom: 16px;
`;

const BorderedTopDiv = styled.div`
  border-top: 1px solid #e3e3e3;
  padding: 0 8px 4px 8px;
  margin-top: 12px;
`;
const EntryStyledDiv = styled.div`
  padding: 0 8px 4px 8px;
  font-size: 12px;
  font-weight: 600;
`;
const BorderedTopDiv2 = styled.div`
  border-top: 1px solid #e3e3e3;
  padding: 0 8px 4px 8px;
  margin-top: 16px;
`;
const Summary = () => {
  const {
    item: { uid, units, payRate, sleepRate, name, customerId },
    date,
    setDate,
    hideHeaders,
    setHideHeaders,
    expandCustomer,
    setExpandCustomer,
  } = useContext(ReportContext);

  // console.log('payRate ==== ', payRate);
  const { rotaPadShiftsTopic } = useApiService();
  const { triggerToast } = useToast();
  // const [date, setDate] = useState([
  //   new Date(moment().format()),
  //   new Date(moment().format()),
  // ]);

  // console.log('date ==== ', _.find(date, null));
  const computedShifts = useMemo(() => {
    const [startDate, endDate] = date;

    // console.log('rotaPadShiftsTopic ==== ', rotaPadShiftsTopic);
    if (!endDate) {
      // triggerToast({
      //   message: 'Request could not be sent!',
      //   type: TOAST_TYPE.warning,
      // });
      return;
    }

    const getYrRota =
      rotaPadShiftsTopic &&
      rotaPadShiftsTopic[`${moment(startDate).format('YYYY')}`];

    if (!getYrRota) {
      triggerToast({
        message: `${moment(startDate).format('YYYY')} does not have data yet!`,
        type: TOAST_TYPE.warning,
      });
      return;
    }
    // Check if start and end are in diff months
    // TEST FOR DIff YEars TOO!!
    if (moment(startDate).format('MMMM') === moment(endDate).format('MMMM')) {
      // console.log('startDate SAME', moment(startDate).format('MMMM'));
      // console.log('endDate SAME', moment(endDate).format('MMMM'));
      // GEt year, month
      // const getYrRota =
      //   rotaPadShiftsTopic[`${moment(startDate).format('YYYY')}`];
      // console.log(getYrRota);

      const getMonthRota = getYrRota[`${moment(startDate).format('MMMM')}`];
      // console.log('getMonthRota ==== ', getMonthRota);

      const shifts = convertObjectToArray({ item: getMonthRota });

      // console.log('shifts ==== ', shifts);

      // moment().isBetween(beforeTime, afterTime);

      const filteredShifts = shifts.filter((shift) => {
        // end can be undefined
        const { start, end } = shift;

        return end
          ? moment(moment(start).format('YYYY-MM-DD')).isBetween(
              moment(startDate).format('YYYY-MM-DD'),
              moment(endDate).format('YYYY-MM-DD'),
              null,
              '[]'
            ) &&
              moment(moment(end).format('YYYY-MM-DD')).isBetween(
                moment(startDate).format('YYYY-MM-DD'),
                moment(endDate).format('YYYY-MM-DD'),
                null,
                '[]'
              )
          : moment(moment(end).format('YYYY-MM-DD')).isBetween(
              moment(startDate).format('YYYY-MM-DD'),
              moment(endDate).format('YYYY-MM-DD'),
              null,
              '[]'
            );
      });

      // console.log('filteredShifts ===== ', filteredShifts);
      // setFilteredShifts(filteredShifts);
      return filteredShifts;
    } else {
      // const getYrRota =
      //   rotaPadShiftsTopic[`${moment(startDate).format('YYYY')}`];
      // console.log(getYrRota);

      const getFirstMonthRota =
        getYrRota[`${moment(startDate).format('MMMM')}`];
      const getSecondMonthRota = getYrRota[`${moment(endDate).format('MMMM')}`];
      // console.log('getMonthRota ==== ', getMonthRota);

      const firstMonthShifts = convertObjectToArray({
        item: getFirstMonthRota,
      });
      const secondMonthShifts = convertObjectToArray({
        item: getSecondMonthRota,
      });

      const filteredShifts = [...firstMonthShifts, ...secondMonthShifts].filter(
        (shift) => {
          // end can be undefined
          const { start, end } = shift;

          return end
            ? moment(moment(start).format('YYYY-MM-DD')).isBetween(
                moment(startDate).format('YYYY-MM-DD'),
                moment(endDate).format('YYYY-MM-DD'),
                null,
                '[]'
              ) &&
                moment(moment(end).format('YYYY-MM-DD')).isBetween(
                  moment(startDate).format('YYYY-MM-DD'),
                  moment(endDate).format('YYYY-MM-DD'),
                  null,
                  '[]'
                )
            : moment(moment(end).format('YYYY-MM-DD')).isBetween(
                moment(startDate).format('YYYY-MM-DD'),
                moment(endDate).format('YYYY-MM-DD'),
                null,
                '[]'
              );
        }
      );
      // console.log('startDate DIFF', moment(startDate).format('MMMM'));
      // console.log('endDate DIFF', moment(endDate).format('MMMM'));
      // console.log('DIFF filteredShifts ==== ', filteredShifts);
      // setFilteredShifts(filteredShifts);
      return filteredShifts;
    }
  }, [date, rotaPadShiftsTopic]);
  // console.log('date ==== ', computeDate);

  const { count, totalDue, totalHours, totalSleep } = useMemo(() => {
    if (!computedShifts)
      return { count: 0, totalHours: 0, totalDue: 0, totalSleep: 0 };
    // Get all attendees from shiftsCollection
    const attendeesCollection = _.reduce(
      computedShifts,
      function (sum, n) {
        const { attendees } = n;
        return attendees && attendees.length > 0
          ? [...sum, ...attendees]
          : [...sum];
      },
      []
    );

    // console.log('attendeesCollection === ', attendeesCollection);

    // Get all attendees hours
    const attendeesTotalHours = _.reduce(
      attendeesCollection,
      function (sum, n) {
        const { timeEntry } = n;
        return timeEntry ? sum + timeEntry : sum;
      },
      0
    );

    const getTotalSleep = _.reduce(
      attendeesCollection,
      function (sum, n) {
        const { sleepEntry } = n;
        return sleepEntry ? sum + sleepEntry : sum;
      },
      0
    );

    // console.log('attendeesTotalHours === ', attendeesTotalHours);

    return {
      count: computedShifts.length,
      totalHours: attendeesTotalHours,
      totalDue: payRate * attendeesTotalHours + sleepRate * getTotalSleep,
      totalSleep: getTotalSleep,
    };
  }, [computedShifts, payRate, sleepRate]);
  return (
    <>
      <div
        className='d-flex flex-column mt-0 mb-4'
        onClick={() => {
          if (expandCustomer === uid) {
            setExpandCustomer();
          } else {
            setExpandCustomer(uid);
          }
        }}
      >
        <CustomerNameDivStyled
          className='ms-0'
          active={expandCustomer === uid}
        >
          {expandCustomer === uid ? (
            <BsCaretDownFill
              style={{ marginRight: '3px', marginTop: '-3px' }}
            />
          ) : (
            <BsCaretRightFill
              style={{ marginRight: '3px', marginTop: '-3px' }}
            />
          )}

          {name}
        </CustomerNameDivStyled>
        <CustomerIdStyledDIv className='ms-3'>{customerId}</CustomerIdStyledDIv>
      </div>
      {expandCustomer === uid && (
        <div className='border p-2 mb-4'>
          {' '}
          <HeaderToggle
            hideHeaders={hideHeaders}
            setHideHeaders={setHideHeaders}
          />
          <Header computedShifts={computedShifts} />
          <div style={{ display: hideHeaders ? 'inherit' : 'none' }}>
            <div className='d-flex flex-column mt-4 mb-4 py-3'>
              <BorderedBottomDiv className='d-flex justify-content-between'>
                <AtAGlanceStyledDIv>At-a-glance summary</AtAGlanceStyledDIv>
                <DateRangeStyledDIv>
                  <Calendar
                    panelClassName='customPicker p-invalid'
                    value={date}
                    onChange={(e) => setDate(e.value)}
                    // showIcon
                    readOnlyInput
                    selectionMode='range'
                    dateFormat='dd/mm/yy'
                  />
                </DateRangeStyledDIv>
              </BorderedBottomDiv>

              <EntryStyledDiv className='d-flex justify-content-between '>
                <div>
                  {count ? (
                    <>{`${count} ${
                      count === 1 ? 'shift' : 'shifts'
                    } · ${formatHour(totalHours)} hrs · ${formatHour(
                      totalSleep
                    )} sleep cycles`}</>
                  ) : (
                    <>0 shifts</>
                  )}
                </div>
                <div>{formatCurrency(totalDue || 0)}</div>
              </EntryStyledDiv>
              <EntryStyledDiv className='d-flex justify-content-between '>
                <div>add-on services</div>
                <div>{formatCurrency(0)}</div>
              </EntryStyledDiv>

              <BorderedTopDiv className='d-flex justify-content-end text-end'>
                <div className='mt-1'>
                  <TotalDueSpanStyled>Total Due:</TotalDueSpanStyled>{' '}
                  <TotalValueStyledSpan>
                    {formatCurrency(totalDue || 0)}
                  </TotalValueStyledSpan>
                </div>
              </BorderedTopDiv>
            </div>
          </div>
          <Details
            computedShifts={computedShifts}
            units={units}
          />
          <BorderedTopDiv2 className='d-flex justify-content-end text-end'>
            <div className='mt-1'>
              <TotalDueSpanStyled>Total Due:</TotalDueSpanStyled>{' '}
              <TotalValueStyledSpan>
                {formatCurrency(totalDue ? totalDue : 0)}
              </TotalValueStyledSpan>
            </div>
          </BorderedTopDiv2>
        </div>
      )}
    </>
  );
};
export default Summary;
