import { ThemeProvider } from '@emotion/react';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import ReactDOM from 'react-dom/client';
import { Helmet } from 'react-helmet';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import App from './App';
import Absences from './components/absences';
import { URLS } from './components/common/constants';
import { useBranding } from './components/common/hooks/useBranding';
import Dashboard from './components/dashboard';
import UserGroups from './components/groups';
import Invitations from './components/invitations';
import MyProfile from './components/profile';
import Team from './components/team';
import Workflows from './components/customers';
import ViewWorkflow from './components/customers/ViewWorkflow';
import WorkSpace from './components/workspace';
import './index.css';
import Customers from './components/customers';
import ErrorPage from './components/error';
import Reports from './components/reports';
import StartApp from './App';
import RegisterPage from './components/register';
import { useEffect } from 'react';
import CustomerApprovalPage from 'components/customerApproval';
import { AbilityContext } from 'components/context/ability-context';
import ability from 'components/context/ability';
import ForgotPasswordPage from 'components/forgotpassword';
// import App from './App';

const firebaseConfig = {
  apiKey: 'AIzaSyDsCmoTZHVO3hdM918T90Y-NxpOhe3CS0Q',
  authDomain: 'oxfordbiblechurch-c4b54.firebaseapp.com',
  databaseURL: 'https://oxfordbiblechurch-c4b54-default-rtdb.firebaseio.com/',
  projectId: 'oxfordbiblechurch-c4b54',
  storageBucket: 'gs://oxfordbiblechurch-c4b54.appspot.com',
  messagingSenderId: '138227057866',
  appId: '1:138227057866:web:58d6c76a11d4216fac2033',
  measurementId: 'G-2VT41SKJCD',
};

const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const analytics = getAnalytics(app);
// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);
// Initialize Cloud Storage and get a reference to the service
const storage = getStorage(app);

const RotaPadApp = ({
  thisapp,
  thisanalytics,
  thisauth,
  thisdatabase,
  thisstorage,
}: {
  thisapp: any;
  thisanalytics: any;
  thisauth: any;
  thisdatabase: any;
  thisstorage: any;
}) => {
  const { colour, title } = useBranding();

  return (
    <AbilityContext.Provider value={ability}>
      <ThemeProvider theme={colour}>
        <StartApp
          app={thisapp}
          analytics={thisanalytics}
          auth={thisauth}
          database={thisdatabase}
          storage={thisstorage}
        />
        <ToastContainer />
      </ThemeProvider>
    </AbilityContext.Provider>
  );
};
// RegisterPage
const ErrorPageContainer = () => {
  const { colour, title } = useBranding();
  // let urlParams = useParams();

  return (
    <>
      <ThemeProvider theme={colour}>
        <ErrorPage />
      </ThemeProvider>
    </>
  );
};

const RegisterPageContainer = () => {
  const { colour, title } = useBranding();

  return (
    <>
      <ThemeProvider theme={colour}>
        <RegisterPage
          app={app}
          analytics={analytics}
          auth={auth}
          database={database}
          storage={storage}
        />
      </ThemeProvider>
    </>
  );
};

const CustomerApprovalPageContainer = () => {
  const { colour, title } = useBranding();

  return (
    <>
      <ThemeProvider theme={colour}>
        <CustomerApprovalPage
          app={app}
          analytics={analytics}
          auth={auth}
          database={database}
          storage={storage}
        />
      </ThemeProvider>
    </>
  );
};

// ForgotPasswordPage
const ForgotPasswordPageContainer = () => {
  const { colour, title } = useBranding();
  // let urlParams = useParams();

  return (
    <>
      <ThemeProvider theme={colour}>
        <ForgotPasswordPage />
      </ThemeProvider>
    </>
  );
};
const router = createBrowserRouter([
  {
    path: URLS.forgotpassword,
    element: (
      <>
        <ForgotPasswordPageContainer />
      </>
    ),
    errorElement: (
      <>
        <ErrorPageContainer />
      </>
    ),
  },
  {
    path: URLS.approve,
    element: (
      <>
        <CustomerApprovalPageContainer />
      </>
    ),
    errorElement: (
      <>
        <ErrorPageContainer />
      </>
    ),
  },

  {
    path: URLS.onboard,
    element: (
      <>
        <RegisterPageContainer />
      </>
    ),
    errorElement: (
      <>
        <ErrorPageContainer />
      </>
    ),
  },
  {
    path: '/',
    element: (
      <>
        <RotaPadApp
          thisapp={app}
          thisanalytics={analytics}
          thisauth={auth}
          thisdatabase={database}
          thisstorage={storage}
        />
      </>
    ),
    errorElement: (
      <>
        <ErrorPageContainer />
      </>
    ),
    children: [
      {
        index: true,
        element: (
          <Navigate
            to={URLS.dashboard}
            replace={true}
          />
        ),
      },
      // {
      //   // The idea to give the illusion if segements but in actuality we only have segements of months and reports
      //   // months + reports are non global
      //   //multiple instances idea - workspace per insta
      //   path: '/workspace/:Id' + URLS.months,
      //   element: <WorkSpace />,
      // },
      {
        //multiple instances idea - reports per insta
        path: URLS.reports,
        element: <Reports />,
      },
      // {
      //   path: '/workspace/:Id' + URLS.team,
      //   element: <p>workspace team (group members)</p>,
      // },
      {
        path: URLS.absences,
        element: <Absences />,
      },
      {
        path: URLS.team,
        element: <Team />,
      },
      {
        path: URLS.profile,
        element: <MyProfile />,
      },
      {
        path: URLS.groups,
        element: <UserGroups />,
      },
      {
        path: URLS.invitations,
        element: <Invitations />,
      },
      {
        path: URLS.dashboard,
        element: <Dashboard />,
      },
      {
        path: URLS.customers,
        element: <Customers />,
      },
    ],
  },
]);

// ReactDOM.render(
// 	<RouterProvider router={router} />,
// 	document.getElementById("root") as HTMLElement
// );

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
