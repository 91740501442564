import 'bootstrap/dist/css/bootstrap.css';
import 'primereact/resources/primereact.min.css'; //core css
import 'primereact/resources/themes/lara-light-indigo/theme.css'; //theme
import { ReactElement, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import HeaderNav from './components/common/HeaderNav';
import MobileSideNav from './components/common/MobileSideNav';
import SideNav from './components/common/SideNav';
import { ApiServiceProvider } from './components/context';
import Login from './components/login';
import './index.scss';
import { Helmet } from 'react-helmet';
import { ABILITY_TYPES, TITLES, URLS } from 'components/common/constants';
import { AbilityContext } from 'components/context/ability-context';
import { useAbility } from '@casl/react';

const StartApp = ({ app, analytics, auth, database, storage }) => {
  const [clientUser, setClientUser] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visibleLeft, setVisibleLeft] = useState(false);
  const [user] = useAuthState(auth);
  const [pageTitle, setPageTitle] = useState(null);

  const ability = useAbility(AbilityContext);
  const navigate = useNavigate();

  let { pathname } = useLocation();

  useEffect(() => {
    if (pathname) {
      setPageTitle(`${TITLES[pathname]} | RotaPad`);
    }
    // console.log('pathname ==== ', pathname);
    if (
      ability &&
      !ability.can(ABILITY_TYPES.read, TITLES[pathname]) &&
      pathname !== '/'
    ) {
      navigate(URLS.dashboard);
    }
  }, [pathname, ability, navigate]);

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (user: any) => {
      if (user) {
        const token = await user.getIdToken();
        localStorage.setItem('firebaseAuthToken', token);
        localStorage.setItem(
          'firebaseAuthTokenExpireTime',
          user.stsTokenManager.expirationTime.toString()
        );
        setClientUser(user);
        setIsLoading(false);
      } else {
        localStorage.removeItem('firebaseAuthToken');
        localStorage.removeItem('firebaseAuthTokenExpireTime');
        setClientUser(null);
        setIsLoading(false);
      }
    });
    return () => unsubscribe();
  }, [auth]);

  // useEffect(() => {
  // 	const unsubscribe = auth.onIdTokenChanged((user: any) => {
  // 		if (user) {
  // 			console.log("user onIdTokenChanged ===== ", user);

  // 			setUser(user);
  // 		}
  // 	});
  // 	return () => unsubscribe();
  // }, [auth, user]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const tokenExpiration = new Date(
  //       parseInt(localStorage.getItem('firebaseAuthTokenExpireTime') as string)
  //     );
  //     const timeUntilExpiration = +tokenExpiration - +new Date();
  //     if (timeUntilExpiration < 1 * 60 * 1000 && !isRefreshing) {
  //       setIsRefreshing(true);

  //       auth.currentUser
  //         .getIdToken(true)
  //         .then((token: any) => {
  //           localStorage.setItem('firebaseAuthToken', token);
  //           localStorage.setItem(
  //             'firebaseAuthTokenExpireTime',
  //             auth.currentUser.expirationTime.toString()
  //           );
  //           setIsRefreshing(false);
  //         })
  //         .catch((e: any) => {
  //           setError(e);
  //           setIsRefreshing(false);
  //         });

  //       // setTimeout(() => {
  //       // 	window.location.reload();
  //       // }, 2000);
  //     }
  //   }, 60 * 1000);
  //   return () => clearInterval(interval);
  // }, [auth.currentUser, isRefreshing]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!user || !clientUser) {
    return (
      <div className='App'>
        <>
          <Login
            app={app}
            analytics={analytics}
            auth={auth}
            database={database}
            // setUser={setUser}
          />
        </>
      </div>
    );
  }

  if (isRefreshing) {
    return (
      <div>
        <p>Your session has expired. Refreshing page...</p>
      </div>
    );
  }

  return (
    <ApiServiceProvider
      user={user}
      auth={auth}
      database={database}
      storage={storage}
    >
      <Helmet>
        <title>{pageTitle ? pageTitle : 'RotaPad'}</title>
      </Helmet>
      <div className='App'>
        <HeaderNav handleShowSideBar={setVisibleLeft} />
        {/* mobile side nav */}
        <MobileSideNav
          visibleLeft={visibleLeft}
          setVisibleLeft={setVisibleLeft}
          auth={auth}
        />

        <div
          className='container-fluid'
          style={{ height: 'calc(100vh - 59px)' }}
        >
          <div
            className='row'
            style={{ height: 'calc(100vh - 59px)' }}
          >
            {/* side nav */}
            <SideNav
              user={user}
              auth={auth}
              database={database}
              setVisibleLeft={setVisibleLeft}
            />

            <main
              className={`col-md-10 col-lg-10 col-xlg-10 ms-sm-auto px-md-4 overflow-auto position-relative text-start`}
              style={{
                height: 'calc(100vh - 59px)',
                backgroundColor: '#efefef',
              }}
            >
              <div className='my-4'>
                <Outlet />
              </div>
            </main>
          </div>
        </div>
      </div>
    </ApiServiceProvider>
  );
};

export default StartApp;
